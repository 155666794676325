export const ApiEndpoints = {
  Auth: {
    Login: '/auth/login',
    RefreshApi: '/auth/refresh',
  },
  settings: {
    Facilities: {
     AddFacility: '/facilities/add',
     AllFacilities: '/facilities',
     EditFacility: '/facilities/edit'
    },
    Markers: {
      Makers: '/marker',
      GetAllIcons: '/marker/markerIcons'
    },
    Devices: {
      UpdateDevice: '/device/status',
      AllDevices: '/device'
    },
    Buildings: {
    //  AddFacility: '/buildings/add',
     AllBuildings: '/buildings',
     buildingById: '/buildings/buildingById',
    //  EditFacility: '/buildings/edit'
    },
    Floors: {
    //  AddFacility: '/buildings/add',
     AllFloors: '/floors',
     FloorById: '/floors/floorById',
    //  EditFloor: '/floors/edit'
    },
    Gateways: {
      AddGateways: '/gateway/save-gateway',
      GetGateways: '/gateway',
      EditGateways: '/gateway/edit-gateway'
    },
    Groups: {
      allGroups: '/device/all-group',
      Groups: '/device/group'
    },
    DeviceTypes: {
      DeviceType: '/device/type'
    },
  },
  Campus: {
    CampusFacilities: '/facilities/campus',
    DeviceSummary: '/device/summary',
    allDevice: '/device/all',
    GetGroups: '/device/group'
  }
}